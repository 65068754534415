import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import Product from "./Product";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import productsdb from "./productsdb";
import ProductDetail from "./ProductDetail";
import { useLocation, useParams, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { Resend } from "resend"; // for customizable emails with react-email
function Home() {
  //GOOGLE ANALYTICS

  // stream name:
  // 1. www.dollarealms.com

  // stream url :
  //2.https://www.dollarealms.com

  //3,measurment id:
  // G-9JLJQMFFZP

  //4.stream id:
  // 9963974561

  // Below is the Google tag for this account. Copy and paste it in the code of every page of your website, immediately after the <head> element. Don’t add more than one Google tag to each page.
  // <!-- Google tag (gtag.js) -->

  // <script async src="https://www.googletagmanager.com/gtag/js?id=G-9JLJQMFFZP"></script>
  // <script>
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag(){dataLayer.push(arguments);}
  //   gtag('js', new Date());

  //   gtag('config', 'G-9JLJQMFFZP');
  // </script>

  const [products, setProducts] = useState(productsdb);
  const mySectionRef = useRef(null); // section to scroll to for product description
  const { productId } = useParams(); // product that was clicked
  // when page loads/refreshes
  const { state } = useLocation();
  const resend = new Resend("re_iJbFjdoT_49P2jyU8fEfp6zYr8YK5V1ao"); // live resend API key
  const slides = [
    {
      id: 1,
      url: "../images/home_slides/sales_blue.jpg",
    },
    {
      id: 2,
      url: "../images/home_slides/sales_purple.jpg",
    },
    {
      id: 3,
      url: "../images/home_slides/sales_pink2.jpg",
    },
    {
      id: 4,
      url: "../images/home_slides/sales_black.jpg",
    },
  ];
  const slideShow = [...slides];
  useEffect(() => {
    // GA tracking
    ReactGA.send({
      hitType: "pageview",
      page: "/Home",
      title: "Home page viewed",
    });
  }, []);
  // scroll to product description section
  useEffect(() => {
    if (productId) {
      if (mySectionRef.current) {
        mySectionRef.current.scrollIntoView({
          behavior: "smooth", // Optional for smooth scrolling
        });
      }
    }
  }, [productId]); //

  return (
    <Box className="home" sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {slideShow.map((slide) => {
            let i = 0;
            if (slide.id === i)
              return (
                <Grid
                  item
                  sx={{
                    width: { xs: "90%", md: "90%" },
                    height: "auto",
                    mb: { xs: "-280px", md: "-180px" },
                  }}
                  className="home_slidesShow_image"
                  style={{ display: "flex" }}
                  key={i}
                >
                  <img src={slide.url} alt="slides" />
                </Grid>
              );
          })}
          )
        </Grid>
        {state && (
          <Grid item xs={12} className="home__row">
            <Link
              to={`/Home/${state?.result.id}`}
              style={{ textDecoration: "none" }}
            >
              <Product
                id={state?.result.id}
                title={state?.result.title}
                price={state?.result.price}
                rating={state?.result.rating}
                ratings={state?.result.ratings}
                image={state?.result.image}
              />
            </Link>
          </Grid>
        )}
        <Grid item xs={12} className="home__row"></Grid>
        <div id="productClickedId" ref={mySectionRef}>
          {productId && (
            <Grid item xs={12} className="home__row">
              <ProductDetail />
            </Grid>
          )}
        </div>
        <Grid />
        {products.map((prd, i) => {
          return (
            <Grid item xs={6} sm={6} md={4} lg={3} className="home__row">
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-YHX58MM6Z9"
              ></script>
              <script>
                window.dataLayer = window.dataLayer || []; function gtag()
                {window.dataLayer.push(arguments)}
                gtag('js', new Date()); gtag('config', 'G-YHX58MM6Z9');
              </script>
              <Link to={`/Home/${prd.id}`} style={{ textDecoration: "none" }}>
                {/* Products catalog */}
                <Product
                  id={prd.id}
                  title={prd.title}
                  price={prd.price}
                  rating={prd.rating}
                  ratings={prd.ratings}
                  image={prd.image}
                  seller={prd.seller}
                  description5={prd.description5}
                />
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
export default Home;
//   return (
//     <div className="home">
//       <div className="home_container">
//         <img className="home_image" src="../images/homebackground.jpg" />
//         {/* // src='https://images-eu.ssl-images-amazon.com/images/G/02/digital/video/merch2016/Hero/Covid19/Generic/GWBleedingHero_ENG_COVIDUPDATE__XSite_1500x600_PV_en-GB._CB428684220_,jpg'
//             //             alt='' */}

//         <div className="home__row">
//           <Product
//             id="1"
//             title="Canon - EOS R6 Mirrorless Camera with RF 24-105mm f/4-7.1 IS STM Lens - Black"
//             price={1455.0}
//             rating={5}
//             image={"../images/Canon-EOSR6.jpg"}
//           />
//           <Product
//             id="2"
//             title="Sony -  Camera with RF 24-105mm f/4-7.1 IS STM Lens - Black"
//             price={2255.0}
//             rating={4}
//             image={"../images/Sony-CameraRF.jpg"}
//           />
//         </div>
//         <div className="home__row">
//           <Product
//             id="3"
//             title="Epson V11HA34920 LCD WUXGA EB-PU1007W Laser Projector 7000 Lumens 4K (White)"
//             price={8899}
//             rating={4}
//             image={
//               "../images/Epson V11HA34920LCD WUXGAEB-PU1007WLaser Projector.jpg"
//             }
//           />
//           <Product
//             id="4"
//             title="Epson Pro L1200U WUXGA 3LCD Projector w/ 4K Enhancement & Standard Lens V11H734020"
//             price={5999.0}
//             rating={4}
//             image={"../images/EpsonProL1200U WUXGA3LCD Projector.jpg"}
//             // image ={'https://images-na.ssl-images-amazon.com/images/I/816ctt5WV5L._AC_SX385_.jpg'}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Home;
