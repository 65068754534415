import React, { useState } from "react";
import { DialogTitle } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { blue } from "@mui/material/colors";
import { Resend } from "resend"; // for customizable emails with react-email
// import emailjs from "@emailjs/browser";
import "./EmailForm.css";
import axios from "axios";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={50} ref={ref} variant="filled" {...props} />;
});

export default function EmailFormV2() {
  const [open, setOpen] = React.useState(false);
  const [isYes, setIsYes] = React.useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  // const resend = new Resend(process.env.RESEND_API_KEY);
  const resend = new Resend("re_iJbFjdoT_49P2jyU8fEfp6zYr8YK5V1ao");

  // const handleEmail = async () => {
  //   const response = await axios({
  //     method: "post", //
  //     url: `/emails`, //
  //   });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    // handleEmail();
    // resend.emails.send({
    //   from: "Dollarealms LLC <onboarding@resend.dev>",
    //   to: ["dollarealms@gmail.com"],
    //   subject: "Message from Front end",
    //   html: `<div></div><p>Congrats from dollarealms.com
    //             <strong>second email sent</strong>!</p>
    //             <hr/>
    //             <p> ${message}</p>
    //         </div>`,
    // });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Your Emailjs service ID, template ID, and public Key
  //   const serviceId = "service_cxnb3fh";
  //   const templateId = "template_ztyk5gj";
  //   const publicKey = "F294h0SsjLIVCSZtx";
  //   // create a new object that contains dynamic template params
  //   const templateParams = {
  //     from_name: name,
  //     from_email: email,
  //     to_name: "Customer Service",
  //     to_email: "dollarealms@gmail.com",
  //     message: message,
  //   };
  //   emailjs
  //     .send(serviceId, templateId, templateParams, publicKey)
  //     .then((response) => {
  //       //console.log("Email sent successfully", response);
  //       setName(" MESSAGE SENT !");
  //       setEmail("");
  //       setMessage("");
  //     })
  //     .catch((error) => {
  //       console.log("Error sending email", error);
  //       setName(" MESSAGE NOT SENT !");
  //     });
  // };

  const handleYesClick = () => {
    setIsYes(true);
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsYes(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <div>
        <label
          style={{
            height: 20,
            width: 60,
            marginTop: "10px",
            fontSize: "12px",
            fontStyle: "underline",
            color: "white",
            hover: {
              cursor: "pointer",
              color: "white",
            },
          }}
          onClick={handleClickOpen}
        >
          Help | Customer Service | Inquiry
          <hr />
        </label>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>dollarealms.com</DialogTitle>
        <hr />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form
              onSubmit={handleSubmit}
              className="emailForm"
              width="100%"
              style={{
                alignItems: "left",
                fontSize: "1rem",
                marginTop: "2px",
              }}
            >
              <div style={{ marginLeft: "20px", marginTop: "2px" }}>
                <h1>Customer Service</h1>
                <br />
                We will be glad to assist
                <p style={{ width: "90%", fontSize: "1rem", margin: "20px" }}>
                  Please explain in detail what assistance you need
                </p>
                <input
                  style={{
                    width: "98%",
                    height: "30px",
                    marginLeft: "1px",
                    marginTop: "10px",
                  }}
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  style={{ width: "98%", height: "30px", marginLeft: "1px" }}
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                  style={{ width: "100%", marginLeft: "1px" }}
                  placeholder="Explain What you are contacting us for"
                  cols="50"
                  rows="10"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <button
                  type="submit"
                  style={{
                    height: "40px",
                    width: "99%",
                    marginLeft: "1px",
                    marginBottom: "30px",
                  }}
                >
                  Send
                </button>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <hr />
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={isYes}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          We are always here for you
        </Alert>
      </Snackbar>
    </Stack>
  );
}
