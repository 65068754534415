import React, { useEffect } from "react";
import "./Checkout.css";
import Subtotal from "./Subtotal";
import { useStateValue } from "./StateProvider";
import CheckoutProduct from "./CheckoutProduct";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

const theme = createTheme({
  typography: {
    h1: { fontSize: "0.5rem" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
      "3xl": 1920,
      "4xl": 2560,
      "5xl": 3200,
    },
  },
});

function Checkout({ deliveryAddressDocPath }) {
  const [{ basket, user }, dispatch] = useStateValue();
  const navigate = useNavigate();
  useEffect(() => {
    if (basket.length === 0) {
      // alert("No items in the shopping cart\n..Please select items to buy");
      navigate("/Home");
      return; // a neccesary line
    }
  }, [basket]);
  return (
    <ThemeProvider
      theme={responsiveFontSizes(theme, {
        breakpoints: ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl"],
        factor: 1,
      })}
    >
      <Grid item xs={12} className="checkout">
        {/* <Grid xs={12} lg={6} className="checkout__left">
          <img
            className="checkout__ad"
            src="../images/checkoutBanner.jpg"
            alt=""
          />
        </Grid>
        */}
        <Grid item xs={6} lg={6}>
          <Box>
            {/* <h3>Helo, {user ? user.email : ""} </h3> */}
            {/* <h2 className="checkout__title">Your shopping basket</h2> */}
            {/* // display each instance( item ) of CheckoutProduct on Checkout.js page : */}
            {basket.map((item) => (
              <CheckoutProduct
                key={item.id}
                id={item.id}
                title={item.title}
                image={item.image}
                price={item.price}
                rating={item.rating}
                ratings={item.ratings}
              />
            ))}
          </Box>
        </Grid>
        {/* end of checkou_left */}
        <Grid item xs={6} lg={6}>
          <Box className="checkout__right">
            <Subtotal />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
export default Checkout;
