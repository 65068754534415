import "./App.css";
import { db } from "./firebase";
import { collection, onSnapshot } from "@firebase/firestore";
import { useState, useEffect } from "react";
import { useStateValue } from "./StateProvider";
import "./Orders.css";
import CheckoutProduct from "./CheckoutProduct";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
function Orders() {
  const [orders, setOrders] = useState([]);
  const [{ basket, user }, dispatch] = useStateValue();

  collection(db, `/users/${user?.uid}/orders/`);
  useEffect(() => {
    if (user) {
      const colRef = collection(db, `/users/${user?.uid}/orders/`);
      onSnapshot(colRef, (snapshot) =>
        setOrders(
          snapshot.docs.map((doc) => ({
            // our customized obj
            id: doc.id,
            myData: doc.data(), // original obj from database
          }))
        )
      );
      console.log("MY ORDERS", `/users/${user?.uid}/orders/`);
      console.log(orders);
    } else {
      setOrders([]); // if no user , then set to empty array
      return;
    }
  }, [user]);

  return orders?.map((order) => {
    return (
      <div className="order" key={order.id}>
        <h2>Order</h2>
        <p>
          <b>
            {moment.unix(order.myData.created).format("MMMM Do YYYY, h:mma")}
          </b>
        </p>
        <small>Order id : </small>
        <small key={order.id}>{order.id}</small>
        <h4>Destination:</h4>
        <p>
          {`
          ${order.myData.packageReceiver?.a_receiverFirstName}
          ${order.myData.packageReceiver?.b_receiverInitial}
          ${order.myData.packageReceiver?.c_receiverLastName},
          ${order.myData.packageReceiver?.d_houseNo}
          ${order.myData.packageReceiver?.e_street}
          ${order.myData.packageReceiver?.f_appartmentNo},
          ${order.myData.packageReceiver?.g_townORcity}
          ${order.myData.packageReceiver?.h_stateOrProvince},
          ${order.myData.packageReceiver?.i_zipORareaCode},
          ${order.myData.packageReceiver?.j_country},
          Phone: 
          ${order.myData.packageReceiver?.k_phone}
          `}
        </p>
        <p className="order_id"></p>
        {order.myData.basket?.map((item) => (
          <div key={item.id}>
            <CheckoutProduct
              id={item.id}
              title={item.title}
              image={item.image}
              price={item.price}
              rating={item.rating}
              hideButton={true}
            />
          </div>
        ))}
        <CurrencyFormat
          renderText={(value) => (
            <h3 className="order_total">Order Total:{value}</h3>
          )}
          decimalScale={2}
          value={order.myData.amount / 100}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </div>
    );
  });
}
export default Orders;
