import React from "react";
import ReactDOM from 'react-dom/client';// from "react-dom"; 
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reducer, { initialState } from "./reducer";
import { StateProvider } from "./StateProvider";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

 

import { PostHogProvider} from 'posthog-js/react'
const promise = loadStripe(
  // no need to hide publishable key :
  "pk_test_51L09miAhbJupDqIgKB1UEJLJ1Zoan23ReiUuJLAE9QuMgzTiwEVq0fDEUpad0LNOpFWzCmUsNyVa5nbQ549fZrBv00rGuUORL3"
);

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
       <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
    {/* State provider */}
    <StateProvider initialState={initialState} reducer={reducer}>
      {/* Stripe Elements provider */}
      <Elements stripe={promise}>
        {/*Wrap so useStripe(),useElements() can work inside Payment.js  */}
        <App /> {/* use Stripe Elements to Wrap our App */}
      </Elements>
    </StateProvider>
    </PostHogProvider>
  </React.StrictMode>,
  // document.getElementById("root")
);

// serviceWorker.unregister();


 