import React, { useEffect } from "react";
import "./Product.css";
import { useStateValue } from "./StateProvider";
import ReactGA from "react-ga4";
function Product({
  id,
  title,
  image,
  price,
  rating,
  ratings,
  seller,
  description5,
}) {
  // to pull data from datalayer
  const [{ basket }, dispatch] = useStateValue(); //  <== if we distructure the state to get the basket

  //const [state , dispatch ] = useStateValue();        // state is the state of the global store

  //console.log('Produc.js:::this is the basket >>> ', basket);

  // lets dispatch the items selected
  const addToBasket = (event) => {
    event.preventDefault();

    // dispatching the item into the data layer
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        // id: id,
        id: basket?.length + 1,
        title: title,
        image: image,
        rating: rating,
        ratings: ratings,
        price: price,
        seller: seller,
        description5: description5,
      },
    });

    // Send a custom event
    ReactGA.event({
      category: "Basket category",
      action: `${basket.length} item(s) added to basket by user`,
      label: "Shopping Cart label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };
  return (
    <div className="product">
      <img className="product_img" src={image} alt="prd" />
      <div className="product_info" key={id}>
        <h6>{title}</h6>
        <h6>{seller}</h6>
        <h6>{description5}</h6>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div className="product_rating">
            {Array(rating)
              .fill()
              .map((_, index) => {
                return (
                  <p key={index} className="star_color">
                    &#9733;
                  </p>
                );
              })}
          </div>
          <h6>{ratings} ratings</h6>
        </div>
        <div className="product_price">
          <small>$</small>
          <strong>{price}</strong>
        </div>
      </div>
      <div>
        <button className="addToCart" onClick={addToBasket}>
          Add to Cart now
        </button>
      </div>
    </div>
  );
}

export default Product;
