import * as React from "react";
import { DialogTitle } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { blue } from "@mui/material/colors";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={50} ref={ref} variant="filled" {...props} />;
});
export default function Pricing() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <label
        style={{
          height: 20,
          width: 60,
          textTransform: "inherit",
          color: "gray",
          fontWeight: 100,
          // fontFamily: "fantasy",
          textDecoration: "none",
          "&:hover": {
            cursor: "pointer",
            color: "blue",
          },
        }}
        onClick={handleClickOpen}
      >
        Pricing
      </label>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Pricing Policy</DialogTitle> <hr />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <h4>PRICING ON OUR WEBSITE</h4>
              Our prices do change but not often. Our List Price is the retail
              price of a product as displayed. We regularly check List Prices
              against prices displayed by other sellers. If a products has a
              "Was Price" displayed, this means the recent price found in our
              price history of such product.
            </div>
            <div>
              <br />
              <h4>PRICING CONFIRMATION | ERRORS</h4>
              We cannot confirm the price of a product until an order has been
              placed. Even though we have a commited team efforts, a very short
              range of our products may be mispriced. If the correct price of a
              product sold by us is higher than our displayed price, we use our
              discretion to either contact you for instructions before we ship
              the product to you or we cancel your order and inform you that
              your order has been cancelled with reasons.
            </div>
          </DialogContentText>
        </DialogContent>
        <hr />
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          We are always here for you
        </Alert>
      </Snackbar>
    </Stack>
  );
}
