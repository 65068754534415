import React from "react";
import "./CheckoutProduct.css";
import { useStateValue } from "./StateProvider";

function CheckoutProduct({
  id,
  image,
  title,
  price,
  rating,
  ratings,
  hideButton,
}) {
  const [{ basket }, dispatch] = useStateValue();

  const removeFromBasket = () => {
    //remove the item from basket
    dispatch({ type: "REMOVE_FROM_BASKET", id: id });
  };

  return (
    <div className="checkoutProduct">
      <img className="checkoutProduct__image" src={image} alt="imag" />
      <div className="checkoutProduct__info" key={id}>
        <h6 className="checkoutProduct__title">{title}</h6>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          {" "}
          <div className="checkoutProduct__rating">
            {Array(rating)
              .fill()
              .map((_, index) => {
                return (
                  <p className="star_color" key={index}>
                    &#9733;
                  </p>
                );
              })}
          </div>
          <h6 className="checkoutProduct__ratings">{ratings} ratings</h6>
        </div>{" "}
        <p className="checkoutProduct__price">
          <small> $ </small>
          <strong> {price} </strong>
        </p>
        {!hideButton && (
          <button onClick={removeFromBasket}> Remove from Cart </button>
        )}
      </div>
    </div>
  );
}

export default CheckoutProduct;
