import * as React from "react";
import { DialogTitle, Link } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { yellow } from "@mui/material/colors";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={50} ref={ref} variant="filled" {...props} />;
});
export default function Returns() {
  const [open, setOpen] = React.useState(false);
  const [isYes, setIsYes] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsYes(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <label
        style={{
          height: 20,
          width: 60,
          color: "gray",
          fontWeight: 100,
          // fontFamily: "fantasy",
          textDecoration: "none",
          "&:hover": {
            cursor: "pointer",
            color: "blue",
          },
        }}
        onClick={handleClickOpen}
      >
        Returns&Refund
      </label>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Return/Refund Policy and the process</DialogTitle>
        <hr />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <div>
                The period of time for you to return an item to us if you are
                based in the USA , Canada , Australia, New Zealand is within 30
                days of you receiving your item(s).{" "}
              </div>
              <br />
              <div>USA RETURNS </div>
              <br />
              <p>
                Your item(s) must be returned to us within 30 days of receiving
                those item(s)
              </p>
              <br />
              <p>The Process </p>
              <br />
              1. Log in on our website.Type/select what you are returning . Tell
              us the reason for returning the item(s). Then print your Returns
              label. Attach the label to the package you are returning.
              <br />
              The eParcel Return service costs $4.00 per order. This payment can
              be made with either a credit card or PayPal and you can track your
              return as it makes its way back to us. <br />
              2. Go and post the package and be sure you store your proof of the
              postage. We will send you an email as soon as your return arrives
              back to our facility, and another email when we have processed
              your refund.
              <br />
              3.Once we receive your returns it takes about 3 business days to
              process your refund.So once our checks are complete we will begin
              the process of refund to your credit/debit card
              <br />
              4.Your refund takes about 7 working days (excluding weekends and
              bank holidays) to show in your bank account,depending on the
              processing time of your bank.
              <br />
              <br />
              <div>Descripancy</div>
              <br />
              <p>
                If there is a discrepancy between the value of the returns you
                filled in on our website and the actual item(s) price, we
                reserve the right to recover any shortfall from you but this
                will be after we have notified you about such descrepancy..
              </p>
              <br />
              <div>Exchange</div>
              <br />
              <p>
                At the moment we do not exchange any items we have delivered to
                our customers . Instead of exchanging an item, we can only offer
                a refund of your money once we have received back the item(s)
                from you. What you can do is to return your item to us for a
                refund, and then place a new order so we can deliver to you the
                item of your choice.
              </p>
              <br />
              <div>Incorrect / Faulty Items</div>
              <p>
                <br />
                We do appologize for any incorrect or faulty item you may have
                received. Please contact us using the <Link href="">help</Link>
                &nbsp; page we have provided on our website. Make sure to
                provide your order number. Our customer service agents will be
                on top of this matter for you.
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <hr />
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={isYes}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          We are always here for you
        </Alert>
      </Snackbar>
    </Stack>
  );
}
