import React, { useState, useEffect } from "react";
import "./ProductDetail.css";
import { useStateValue } from "./StateProvider";
import Returns from "./Returns";
import Shipping from "./Shipping";
import Pricing from "./Pricing";
import { useParams, useNavigate } from "react-router-dom";
import productsdb from "./productsdb";
//
function ProductDetail({ id, seller }) {
  const navigate = useNavigate();
  // to pull data from datalayer
  const [{ basket }, dispatch] = useStateValue(); //  <== if we distructure the state to get the basket
  const [productDetails, setProductDetails] = useState(productsdb);
  const myId = useParams();
  let productId = myId;
  //const [state , dispatch ] = useStateValue();        // state is the state of the global store
  const productDetail = productDetails.find(
    (prd) => prd.id === productId.productId
  );
  // lets dispatch the items selected
  const addToBasket = (event) => {
    event.preventDefault();
    productId = "";
    // dispatching the item into the data layer
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        // id: id,
        id: basket?.length + 1,
        title: productDetail.title,
        seller: seller,
        image: productDetail.image,
        itemWeight: productDetail.itemWeight,
        packageWeight: productDetail.packageWeight,
        numberOfItems: productDetail.numberOfItems,
        unitCount: productDetail.unitCount,
        price: productDetail.price,
        rating: productDetail.rating,
        ratings: productDetail.ratings,
      },
    });
    navigate("/Home");
  };
  return (
    <div className="product_detail">
      <div
        style={{
          display: "flex",
          flexWrap: true,
          flexDirection: "column",
          gap: 1,
          padding: 2,
          marginTop: 20,
          marginBottom: "5",

          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          className="product_detail_img"
          src={productDetail.image}
          alt="prd"
        />
        <div>
          <button className="product_detail_button" onClick={addToBasket}>
            Add to Basket
          </button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: true,
          gap: 2,
          padding: 10,
          marginTop: 20,
          marginBottom: "5",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div className="product_detail_info" key={id}>
          <h4
            style={{
              fontWeight: 1000,
              color: "black",
            }}
          >
            {productDetail.title}
          </h4>
          <div>
            <h6
              style={{
                fontWeight: 300,
                color: "black",
              }}
            >
              Sold by {productDetail.seller}
            </h6>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div className="product_rating">
              {Array(productDetail.rating)
                .fill()
                .map((star, index) => {
                  return (
                    <p key={index} className="star_color">
                      &#9733;
                      {/* &#9734; */}
                    </p>
                  );
                })}
            </div>
            <h6>{productDetail.ratings} ratings</h6>
          </div>
          <div className="product_detail_price">
            <strong>$</strong>
            <strong>{productDetail.price}</strong>
          </div>
          <div>
            <span className="product_detail_measurement_items">
              <h6 className="product_detail_measurement_type">Item Weight</h6>
              <h6 className="product_detail_measurement_value">
                {productDetail.itemWeight}
              </h6>
            </span>
            <span className="product_detail_measurement_items">
              <h6 className="product_detail_measurement_type">
                Package Weight
              </h6>
              <h6 className="product_detail_measurement_value">
                {productDetail.packageWeight}
              </h6>
            </span>
            <span className="product_detail_measurement_items">
              <h6 className="product_detail_measurement_type">
                Number Of Items
              </h6>
              <h6 className="product_detail_measurement_value">
                {productDetail.numberOfItems}
              </h6>
            </span>

            <span className="product_detail_measurement_items">
              <h6 className="product_detail_measurement_type">Unit Count</h6>
              <h6 className="product_detail_measurement_value">
                {productDetail.unitCount}
              </h6>
            </span>
          </div>
        </div>

        <div className="product_detail_info">
          <hr />
          <div>
            <br />
            <p>About this item</p>
            <br />
          </div>
          <ul>
            <li>
              <h6>{productDetail.description1}</h6>
            </li>
            <li>
              <h6>{productDetail.description2}</h6>
            </li>
            <li>
              <h6>{productDetail.description3}</h6>
            </li>
            <li>
              <h6>{productDetail.description4}</h6>
            </li>
            <li>
              <h6>{productDetail.description5}</h6>
            </li>
          </ul>
        </div>
        <div className="product_shipping_info">
          {<Shipping />}
          <br />
          {<Pricing />}
          <br />
          {<Returns />}
        </div>
        <div>
          <button className="product_detail_button" onClick={addToBasket}>
            Add to Basket
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
