import React, { useState } from "react";
import { Box } from "@mui/material";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "./firebase";
import "./LogIn.css";
import EmailForm2 from "./EmailFormV2";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("..");
  const [logedInUser, setLogedInUser] = useState("Gest");

  const navigate = useNavigate();
  const onLogin = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in

        const user = userCredential.user;
        alert("Log in successful " + " \n\n Click Ok to continue ....");
        navigate("/Home");
        // ...
      })
      .catch((error) => {
        alert(
          error.message +
            "\n\n Invalid Login !!!! ....\n\nEnter the correct username and password"
        );
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }; // End of Sign in

  const Register = (e) => {
    e.preventDefault();
    //const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        alert(
          user.email +
            " was created successfully \n\nClick OK or Close to continue"
        );
        navigate("/payment");
        console.log(userCredential);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert("User not created due to \n\n" + error.message);
        // ..
      });
  }; // End of Register

  return (
    <div className="login">
      {/* <Link to="/"> */}
      {/* clicking the company logo navigates user to homepage */}
      {/* <img */}
      {/* // className="login_logo" // // src="../images/Home_logo_.jpg" // /> */}
      {/* </Link> */}
      <header
        style={{
          padding: 10,
          fontSize: 45,
          fontWeight: 900,
          textDecoration: "none",
        }}
      >
        <Link
          style={{
            textDecoration: "none",
            color: "white",
            fontSize: "57px",
            fontWeight: 900,
          }}
          to="/"
        >
          Dollarealms Free Delivery
          <hr style={{ borderTop: "0px solid blue" }} />
        </Link>
      </header>
      <div className="login_container" style={{ backgroundColor: "#FF3131" }}>
        <h1> Log in</h1>
        <form>
          <h5>E-mail</h5>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />{" "}
          {/* input.text =>>> input.value */}
          <h5>Password</h5>
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="login_signInButton"
            type="submit"
            title="if you have already created an account please enter the existing email and password to log in"
            onClick={(event) => onLogin(event)}
          >
            Log In
          </button>
          <button
            title="Any email and password you enter will create the new account if it doesn't exist"
            className="login_registerButton"
            onClick={Register}
          >
            Create A New Account
          </button>
          <p>
            By continuing, you agree to Dollarealms Conditions of Use and
            Privacy Notice.
          </p>
          <br />
        </form>
        <Box
          style={{
            justifyItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            textDecoration: "none",
            color: "white",
            fontSize: "17px",
            fontWeight: 900,
          }}
        >
          <div>
            <h5 className="login_needHelp">Need help?</h5>
            <Link to="/">
              <h5 className="login_forgotPassword">Forgot your password?</h5>
            </Link>
            <Link to="/">
              <h5 className="login_otherIssues">Other issues with Log-In</h5>
            </Link>
            <p>
              <h5>
                For assistance use the link below :
                <br />
              </h5>
            </p>
          </div>
          <div cursor="pointer">
            <EmailForm2 />
          </div>
        </Box>
      </div>
      {/* login signIncontainer__title*/}
    </div>
  );
};

export default SignIn;
