import React, { useEffect, useState, useRef } from "react";
import CheckoutProduct from "./CheckoutProduct";
import { useStateValue } from "./StateProvider";
//import { Link } from "react-router-dom";
import "./Payment.css";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Table, TableBody } from "@mui/material";

import CurrencyFormat from "react-currency-format";
import { getBasketTotal } from "./reducer";
import axios from "./axios"; // we pull from our local file axios
import { Link, useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { doc, setDoc, getDocs } from "firebase/firestore";
import AddNew from "./AddNew";

import "./EmailForm.css";
import ReactGA from "react-ga4";

function Payment(event) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(true);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState("");
  const [clientSecret, setClientSecret] = useState(true); // necessary before requesting Stripe to process
  const [{ basket, user }, dispatch] = useStateValue();
  // EMAIL

  // DELIVERY ADDRESS VARIABLES
  const [paymentIntentDeliveryAddress, setPaymentIntentDeliveryAddress] =
    useState(null);
  const receiverFirstName = useRef(" ");
  const receiverInitial = useRef(null);
  const receiverLastName = useRef(null);
  const houseNo = useRef(null);
  const street = useRef(null);
  const townORcity = useRef(null);
  const stateOrProvince = useRef(null);
  const appartmentNo = useRef(null);
  const zipORareaCode = useRef(null);
  const country = useRef(null);
  const phone = useRef(null);
  const [val, setIPv4] = useState(null);
  const [deliveryAddressValidation, setDeliveryAddressValidation] =
    useState(" ");

  useEffect(() => {
    //
    if (basket.length === 0) {
      navigate("/Home"); // go to Home so you can add to basket
      return; // a neccesary line
      console.log("Basket for email : /n", basket);
    }
    // generate the special stripe secret which allows us to charge customer a new total price when basket items change
    //1.create async inside useEffect, to await axios fetches
    if (!user) {
      return;
    }
    const getClientSecret = async () => {
      const response = await axios({
        method: "post", // create a post request to query the BACK END to  get the total amount in the basket
        url: `/payments/create?total=${getBasketTotal(basket) * 100}
       `, // total must be converted to basic units, eg to cents
      });
      // this is a authentication to receive money
      setClientSecret(response.data.clientSecret); // <== hold the secrete in a session state at the front end
    };
    //2.call the async inside the useEffect
    getClientSecret();
  }, [basket]); // END OF useEffect when page loads/refreshes

  // console.log("THE SECRETE IS >>>", clientSecret);

  const handleSubmit = async (event) => {
    /* handles Stripe payment form submit button */
    event.preventDefault();
    // delivery data validation
    if (
      receiverFirstName.current.value.length <= 0 ||
      receiverInitial.current.value.length <= 0 ||
      receiverLastName.current.value.length <= 0 ||
      houseNo.current.value.length <= 0 ||
      street.current.value.length <= 0 ||
      townORcity.current.value.length <= 0 ||
      stateOrProvince.current.value.length <= 0 ||
      zipORareaCode.current.value.length <= 0 ||
      country.current.value.length <= 0 ||
      phone.current.value.length <= 0
    ) {
      alert(
        "Only apartment no is optional \n All other delivery fields are required .. "
      );
      setDeliveryAddressValidation(
        "Please enter all delivery information ! \n Only Apartment No is optional"
      );
      return;
    }
    // new code
    if (!stripe || !elements) {
      return;
    }

    // disables the Buy Now button during payment processing, to avoid double charge
    //
    setProcessing(true);
    // fetch('https://get-ip-only.herokuapp.com/')

    const payload = await stripe
      .confirmCardPayment(
        clientSecret,
        { payment_method: { card: elements.getElement(CardElement) } }
        // destructure the response obj and then obtain paymentIntent that we need:
      )
      .then(async ({ paymentIntent }) => {
        // console.log(`\n\n\n\n ${user} \n\n\n`);
        fetch("https://geolocation-db.com/json/")
          .then((r) => r.json())
          .then((resp) => {
            // console.log("/N/N/N LOCATION:", resp.IPv4 + "/N/N/N");
            // alert(resp.IPv4);
            setIPv4(resp.IPv4);
            ReactGA.send({
              hitType: "payment",
              page: "/payment",
              title: `payment successful for ${basket.length} items`,
            });
          });
        setPaymentIntentDeliveryAddress(paymentIntent.id);
        const docRef = doc(db, "users", user?.uid, "orders", paymentIntent.id); // name.current.value =>> id
        var appartment = "";
        if (appartmentNo.current.value.length > 0) {
          appartment = `Apt ` + appartmentNo.current.value;
        }
        //Platform and Seller turnover
        const totalPurchase = paymentIntent.amount / 100;
        const stripe2_9_percent_plus_30_cent = 0.029 * totalPurchase + 0.3;
        const sharableAmount = totalPurchase - (0.029 * totalPurchase + 0.3);
        const platformAmount = sharableAmount - 0.7 * sharableAmount;
        const sellerAmount = sharableAmount - 0.3 * sharableAmount;

        console.log(``);
        await setDoc(docRef, {
          basket: basket, // basket item
          amount: paymentIntent.amount,
          created: paymentIntent.created,
          packageReceiver: {
            a_receiverFirstName: receiverFirstName.current.value,
            b_receiverInitial: receiverInitial.current.value,
            c_receiverLastName: receiverLastName.current.value,
            d_houseNo: houseNo.current.value,
            e_street: street.current.value,
            f_appartmentNo: appartment,
            g_townORcity: townORcity.current.value,
            h_stateOrProvince: stateOrProvince.current.value,
            i_zipORareaCode: zipORareaCode.current.value,
            j_country: country.current.value,
            k_phone: phone.current.value,
            // l_val: val,
          },
          revenue: {
            totalPurchase: totalPurchase,
            stripe2_9_percent_plus_30_cent: stripe2_9_percent_plus_30_cent,
            sharableAmount: sharableAmount,
            platformAmount: platformAmount,
            sellerAmount: sellerAmount,
          },
        }); // note: if we use addDoc id will be autogenerated for us

        // send email to user
        // send email dollarealms
        const response = await axios({
          method: "post", //
          url: `/emails`,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ basket }),
        });
        window.location.replace("/orders");

        fetch("https://geolocation-db.com/json/")
          .then((r) => r.json())
          .then((resp) =>
            console.log("/N/N/N LOCATION:", resp.IPv4 + "/N/N/N")
          );

        console.log(`\n\n\n\n ${user} \n\n\n`);

        //paymentIntent = payment confirmation
        setSucceeded(true);
        setError(null);
        setProcessing(false); // replace this url immediately:
        //dispatch into redux
        dispatch({
          type: "EMPTY_BASKET",
        });
        console.log(`\n\n\n\n Stripe Payment Successful ! \n`);
        console.log(
          `\n window.location.replace('/orders')  ===>> replaced payment url with orders url\n\n\n\n\n`
        );
      });
  };

  const handleChange = (event) => {
    // Listens for changes in the CardElement
    // and displays any errors as the customer types their card details
    setDisabled(event.empty); // if no event took place disable Credit CardElement
    setError(event.error ? event.error.message : ""); // if any Credit CardElement error show error msg
  };
  /// we must programmatically prevent user from refreshing payment page for security reasons
  // here:
  return (
    <div key={user?.email} className="payment">
      <div
        style={{
          fontWeight: 100,
          color: "black",
          marginLeft: 1,
        }}
      >
        <h5>
          <br />
          <br />
          {/* on clicking link should navigate to checkout page for this number of items */}
          Checking out ( <Link to="/checkout"> {basket?.length} items </Link>)
          <br />
        </h5>
        <div className="payment_title">
          <div>
            <h4>Enter delivery information :</h4>
            <br />
          </div>
        </div>
      </div>

      <div className="deliveryForm_container">
        {/* <form style={{ width: "40vw" }}> */}
        <form>
          <Table>
            <TableBody>
              <tr>
                <th>Delivery Information </th>
                <th>{user?.email}</th>
              </tr>
              <tr>
                <td>
                  <label htmlFor="receiverFirstName">FirstName</label>
                </td>
                <td>
                  <input ref={receiverFirstName} maxLength="20" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="receiverInitial">Initial</label>
                </td>
                <td>
                  <input ref={receiverInitial} maxLength="1" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="receiverLastName" type="text">
                    LastName
                  </label>
                </td>
                <td>
                  <input ref={receiverLastName} maxLength="20" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="houseNo">House No </label>
                </td>
                <td>
                  <input ref={houseNo} type="number" maxLength="10" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="street">Street </label>
                </td>
                <td>
                  <input ref={street} maxLength="100" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="appartmentNo">Apartment No </label>
                </td>
                <td>
                  <input ref={appartmentNo} type="number" maxLength="7" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="townORcity">Town Or City </label>
                </td>
                <td>
                  <input ref={townORcity} maxLength="20" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="stateOrProvince">State Or Province</label>
                </td>
                <td>
                  <input ref={stateOrProvince} maxLength="20" />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <label htmlFor="zipORareaCode">Zip Or Area Code </label>
                </td>
                <td>
                  <input ref={zipORareaCode} type="number" maxLength="7" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="country">Country </label>
                </td>
                <td>
                  <input ref={country} maxLength="20" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="phone">Phone or Mobile No </label>
                </td>
                <td>
                  <input ref={phone} type="number" maxLength="15" />
                </td>
              </tr>
            </TableBody>
          </Table>
        </form>
      </div>
      <div className="payment_container">
        {/* Payment section - delivery address */}
        <div className="payment_section">
          <div className="payment_address">
            {/*optional chaining for when the user is undefined */}
          </div>
        </div>
        {/* Payment section - Review Items  */}
        <div className="payment_section">
          <div className="payment_title">
            <h3>Please review your items :</h3>
          </div>

          <div className="payment_items">
            {basket.map(
              (
                item /* to display each shopping-cart/basket item on payment page :*/
              ) => (
                <CheckoutProduct
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  image={item.image}
                  price={item.price}
                  rating={item.rating}
                  hideButton={false}
                />
              )
            )}
          </div>
        </div>
        {/* Payment section - Payment method */}
        <div className="payment_section">
          <div className="payment_title">
            <h5>Payment Method</h5>
          </div>

          <div className="payment_details">
            {/* Stripe payment gateway */}

            <form onSubmit={handleSubmit}>
              <CardElement
                className="card"
                id="card-element"
                width="100px"
                padding="50px"
                onChange={handleChange}
                fontWeight="500"
                iconColor="#c4f0ff"
                color="#blue"
                fontFamily="Roboto, Open Sans, Segoe UI, sans-serif"
                fontSize="16px"
                fontSmoothing="antialiased"
              />
              <div className="payment_priceContainer">
                <CurrencyFormat
                  renderText={(value) => (
                    <div style={{ marginTop: "50px" }}>
                      <h6>{`    Shipping  : ( promotion- free )`}</h6>
                      <h5>{`Order Total : ${value}`}</h5>
                    </div>
                  )}
                  decimalScale={2}
                  value={getBasketTotal(basket)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                <button disabled={processing || disabled || succeeded}>
                  <span>{processing ? <p>Processing</p> : "Buy Now"}</span>
                </button>
              </div>
              {/* Errors  */}
              {error && <div>{error}</div>}
              {error && <div> {setProcessing(false)}</div>}
              {/* re-enable Buy Button*/}
              <div className="deliveryFormErr">{deliveryAddressValidation}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
