import * as React from "react";
import { DialogTitle } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { blue } from "@mui/material/colors";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={50} ref={ref} variant="filled" {...props} />;
});

export default function Shipping() {
  const [open, setOpen] = React.useState(false);
  const [isYes, setIsYes] = React.useState(false);
  const handleYesClick = () => {
    setIsYes(true);
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsYes(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <div>
        <label
          style={{
            height: 20,
            width: 60,
            color: "gray",
            fontWeight: 100,
            // fontFamily: "fantasy",
            textDecoration: "none",
            "&:hover": {
              cursor: "pointer",
              color: "blue",
            },
          }}
          onClick={handleClickOpen}
        >
          Shipping
        </label>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Shipping and Delivery</DialogTitle>
        <hr />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <br />
            <p>Our Shipping Options</p>
            <br />
            <div
              style={{
                color: "red",
                fontWeight: 900,
                fontSize: "12px",
                display: "flex",
                flex: 1,
                flexDirection: "row",
                gap: 20,
                paddingBottom: 40,
              }}
            >
              <span> New Jersey residents Promotion Sales</span>{" "}
              <span>Free Shipping</span>
              <span color="green" fontWeight="1000">
                Within 1 - 2 business days
              </span>
            </div>
            <div
              style={{
                fontWeight: 900,
                fontSize: "12px",
                display: "flex",
                flex: 1,
                flexDirection: "row",
                gap: 20,
                paddingBottom: 40,
              }}
            >
              <span>USA Standard Shipping</span> <span>$9.99</span>
              <span color="green" fontWeight="1000">
                Within 4 - 7 business days
              </span>
            </div>
            <div
              style={{
                fontWeight: 900,
                fontSize: "12px",
                display: "flex",
                flex: 1,
                flexDirection: "row",
                gap: 20,
                paddingBottom: 20,
              }}
            >
              <span>USA Express Shipping</span> <span>$15.99</span>
              <span color="green" fontWeight="1000">
                Within 3 business days
              </span>
            </div>
            <hr />
            <div>
              <br />
              <br />
              <h4>Our Estimated Delivery Windows </h4>
              <br />
              <p>
                Delivery progress tracker may show the Estimated Delivery
                Window. This may also be displayed in Your Orders or any
                notifications you get from us during delivery process. This
                gives you information on when you can expect your deliveries.
                Some uncontrollable factors, such as traffic,snow, may affect
                delivery windows. These are not certain and may change. Your
                deliveries may arrive before or after estimated windows
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <hr />
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={isYes}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          We are always here for you
        </Alert>
      </Snackbar>
    </Stack>
  );
}
