import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import Checkout from "./Checkout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LogIn from "./LogIn";
import { useEffect } from "react";
import { auth } from "./firebase";
import { useStateValue } from "./StateProvider";
import Payment from "./Payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Orders from "./Orders";
import PolicyNotice from "./PolicyNotice";
import ConditionsOfUse from "./ConditionsOfUse";
import AdsPrivacyChoices from "./AdsPrivacyChoices";
import EmailForm from "./EmailFormV2";
import * as React from "react";
import ReactGA from "react-ga4";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import EmailFormV2 from "./EmailFormV2";

//const stripeApiKey='pk_test_51L09miAhbJupDqIgKB1UEJLJ1Zoan23ReiUuJLAE9QuMgzTiwEVq0fDEUpad0LNOpFWzCmUsNyVa5nbQ549fZrBv00rGuUORL3';
const promise = loadStripe(
  // no need to hide publishable key :
  "pk_test_51L09miAhbJupDqIgKB1UEJLJ1Zoan23ReiUuJLAE9QuMgzTiwEVq0fDEUpad0LNOpFWzCmUsNyVa5nbQ549fZrBv00rGuUORL3"
);

const theme = createTheme({
  typography: {
    h1: { fontSize: "3rem" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
      "3xl": 1920,
      "4xl": 2560,
      "5xl": 3200,
    },
  },
});
//GOOGLE ANALYTICS

// stream name:
// 1. www.dollarealms.com

// stream url :
//2.https://www.dollarealms.com

//3,measurment id:
// G-YHX58MM6Z9

//4.stream id:
// 9963974561

// Below is the Google tag for this account. Copy and paste it in the code of every page of your website, immediately after the <head> element. Don’t add more than one Google tag to each page.
// <!-- Google tag (gtag.js) -->

// <script async src="https://www.googletagmanager.com/gtag/js?id=G-9JLJQMFFZP"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'G-9JLJQMFFZP');
// </script>

const MEASUREMENT_ID = "G-BWNNYW03HR"; // dfds

// ReactGA.send({ hitType: "pageview", page: "/", title: "Custom Title" });
// useEffect(() => {
//   ReactGA.initialize(MEASUREMENT_ID);
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//     title: "App.js",
//   });
// }, []);
// ReactGA.pageview(document.location.pathname);
function App() {
  const [{}, dispatch] = useStateValue(); // useStateValue is from StateProvider.js
  //.. listener  thst Triggers whenever user logs in or logs out

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      // whenever a page is refreshed :
      console.log("\n\n App.js:::The current user is ", authUser + " \n\n");

      if (authUser) {
        // if user is looged in, dispatch a user obj to global storage
        dispatch({ type: "SET_USER", user: authUser });
      } else {
        //if user is logged out dispatch that user's obj to global storage
        dispatch({ type: "SET_USER", user: null });
      }
    });
    ReactGA.initialize(MEASUREMENT_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/Home", title: "Landing Page" });
  }, []);
  return (
    <ThemeProvider
      theme={responsiveFontSizes(theme, {
        breakpoints: ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl"],
        factor: 3,
      })}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "100px" }}>
        <Router>
          <div className="app">
            <Routes>
              <Route path="*" element={<Header />} />
              <Route path="/EmailForm" element={<EmailFormV2 />} />

              {/* remove Header from this <Routes>,
                                                          but show Header for everything outside this <Routes> */}
              <Route path="/LogIn" element={<LogIn />} />
            </Routes>
            {/*  use stripe Element to protect our    "/payment"    */}
            <Elements stripe={promise}>
              <Routes>
                <Route exact path="/payment" element={<Payment />} />
                <Route path="/Orders" element={<Orders />} />
              </Routes>
            </Elements>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="/Home#productClickedId" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Home/:productId" element={<Home />} />
              <Route path="/checkout" element={<Checkout />} />
            </Routes>
          </div>
        </Router>
        <Router>
          <Routes>
            <Route path="/ConditionsOfUse" element={<ConditionsOfUse />} />
            <Route path="/PolicyNotice" element={<PolicyNotice />} />
            <Route path="/AdsPrivacyChoices" element={<AdsPrivacyChoices />} />
            <Route path="*" element={<Footer />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
