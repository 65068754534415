import axios from "axios";
const instance = axios.create({
  baseURL: "https://us-central1-dollarealmsllc.cloudfunctions.net/api",
  // baseURL: "http://localhost:5000/dollarealmsllc/us-central1/api/",
});
export default instance;

//OLD live connectionString:
// local url of our API ( Cloud Function Back end)
// baseURL: "http://127.0.0.1:5000/dollarealmsales/us-central1/api", //  API (cloud function) url
//baseURL: "https://us-central1-dollarealmsales.cloudfunctions.net/api",
//
